.courseFilters {
  position: sticky;
  top: 0;
  z-index: 99;
}

@media (min-width: 500px) {
  .courseFilters {
    top: 68px;
  }
}
