.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: var(--br-xs);
  transition: all 0.3s linear;
  position: relative;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
}

.root {
  background-color: white;
}

.cardImg,
.mobileCardImg {
  border-radius: var(--br-xxs);
  object-fit: cover;
}

.skillTag,
.includedContentPill {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: var(--font-xs);
  padding: 5px 10px;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
}

.skillTag {
  color: var(--theme-blue);
  background-color: var(--bg-light-blue);
}

.cardDetailsWrapper,
.mobileCardDetailsWrapper {
  display: flex;
  gap: 12px;
  padding: 12px;
}

.uspTag,
.mobileUspTag {
  padding: 8px 16px;
  font-weight: var(--font-weight-semiBold);
  font-size: var(--font-sm);
  text-align: center;
  position: absolute;
  top: -2px;
  right: -2px;
  border-radius: 0 var(--br-xxs) 0 var(--br-xs);
}

.uspTag.featured {
  background-color: var(--yellow);
}

.mobileCardDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px;
  height: 100%;
  justify-content: space-between;
}

.mobileCardImg {
  width: 100%;
}

.mobileUspTag {
  position: relative;
  border-radius: 0 0 var(--br-xxs) var(--br-xxs);
}

@media (max-width: 500px) {
  .cardDetailsWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 12px;
  }
  .cardImg {
    width: 100%;
  }

  .uspTag {
    position: relative;
    border-radius: 0 0 var(--br-xxs) var(--br-xxs);
  }
}

.premiumTag {
  position: absolute;
  top: 18px;
  left: -44px;
  z-index: 1;
  width: 150px;
  padding: 4px 0;
  transform: rotate(-45deg);
  font-size: var(--font-sm);
  font-weight: var(--font-weight-semiBold);
  background-color: var(--red);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ctaButton {
  height: fit-content;
  padding: 10px 24px;
}

.ctaButtonMobile {
  display: none;
}

.featured {
  border: 2px solid var(--yellow);
  background-color: var(--bg-light-yellow);
}

.type {
  font-size: var(--font-xs);
  color: var(--text-darker-grey);
  font-weight: var(--font-weight-medium);
  padding: 2px 4px 2px 0;
}

.iconText {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: none;
}
