.root,
.specialization-root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: var(--br-xs);
  transition: all 0.3s linear;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
}

.root {
  background-color: white;
  border: 1.4px solid var(--theme-blue);
  margin: 1px;
}

.pointer {
  cursor: pointer;
}

.specialization-root {
  border: 1.4px solid var(--yellow);
  margin: 1px;
}

.root:hover {
  transform: scale(1.01);
  margin: 1px;
}

.specialization-root:hover {
  border: 1.4px solid var(--yellow);
  margin: 0px;
}

.cardImg {
  border-radius: var(--br-xxs);
  object-fit: cover;
  object-position: top;
  flex: none;
}

.tag, .skillTag,
.includedContentPill {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: var(--font-xs);
  padding: 5px 10px;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
}

.tag {
  color: var(--theme-blue);
  background-color: var(--bg-light-blue);
}

.skillTag {
  color: var(--canva-purple);
  background-color: var(--bg-light-purple);
}

.cardDetailsWrapper {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.uspTag {
  padding: 8px 16px;
  font-weight: var(--font-weight-semiBold);
  font-size: var(--font-sm);
  position: relative;
  border-radius: 0 0 var(--br-xs) var(--br-xs);
}

.imgTag {
  padding: 4px 8px;
  font-weight: var(--font-weight-semiBold);
  font-size: var(--font-xs);
  position: absolute;
  border-radius: var(--br-xxs) 0 var(--br-xxs) 0;
  right: 0;
  bottom: 4px;
}

.imgWrapper {
  position: relative;
}

@media (max-width: 500px) {
  .uspTag {
    text-align: center;
  }

  .imgWrapper {
    width: 100%;
  }

  .cardImg {
    width: 100%;
  }

  .cardDetailsWrapper {
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }

  .ctaButton {
    width: 100%;
  }
}

@media (min-width: 500px) {
  .uspTag {
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 0 var(--br-xxs) 0 var(--br-xs);
  }

  .imgTag {
    padding: 4px 8px;
    font-weight: var(--font-weight-semiBold);
    font-size: var(--font-xs);
    position: absolute;
    border-radius: var(--br-xxs) 0 var(--br-xxs) 0;
    right: 0;
    bottom: 4px;
  }
}

.premiumTag {
  position: absolute;
  top: 18px;
  left: -44px;
  z-index: 1;
  width: 150px;
  padding: 4px 0;
  transform: rotate(-45deg);
  font-size: var(--font-sm);
  font-weight: var(--font-weight-semiBold);
  background-color: var(--red);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ctaButton {
  height: fit-content;
  background-color: var(--yellow);
  padding: 10px 28px;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  text-align: center;
  cursor: pointer;
  flex: none;
  border: none;
}

.ctaButtonMobile {
  display: none;
}

.featured {
  border: 2px solid var(--yellow) !important;
  background-color: var(--bg-light-yellow);
}

.specialization-pill-featured {
  background-color: var(--yellow);
}

.type {
  font-size: var(--font-xs);
  color: var(--text-darker-grey);
  font-weight: var(--font-weight-medium);
  padding: 5px 5px 5px 0;
}

.description {
  font-size: var(--font-xs);
  color: var(--text-darker-grey);
  font-weight: var(--font-weight-medium);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 4px 0 0 0;
}

.trunc-1 {
  -webkit-line-clamp: 1;
}

.trunc-2 {
  -webkit-line-clamp: 2;
}

.iconText {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: none;
}

.specialization-pill {
  background-color: var(--bg-light-yellow);
  color: var(--black);
}

.specialization-root:hover .specialization-pill {
  background-color: var(--yellow);
  color: var(--black);
}

.project-root:hover .project-pill {
  background-color: var(--dark-text-green);
  color: var(--light-bg-green);
}

.course-root:hover .course-pill {
  background-color: var(--dark-purple);
  color: var(--bg-light-purple);
}

.assessment-pill {
  background-color: var(--bg-light-orange);
  color: var(--text-dark-orange);
}

.assessment-root {
  border: 1.4px solid var(--text-dark-orange);
  margin: 1px;
  transition: all 0.3s linear;
}

.assessment-root:hover {
  border: 1.4px solid var(--text-dark-orange);
  margin: 0px;
}

.scholarship-pill {
  background-color: var(--light-bg-green);
  color: var(--dark-text-green);
}

.scholarship-root {
  border: 1.4px solid var(--dark-text-green);
  margin: 1px;
  transition: all 0.3s linear;
}

.scholarship-root:hover {
  border: 1.4px solid var(--dark-text-green);
  margin: 0px;
}

.project-pill {
  background-color: var(--light-bg-green);
  color: var(--dark-text-green);
}

.project-root {
  border: 1.4px solid var(--dark-text-green);
  margin: 1px;
  transition: all 0.3s linear;
}

.project-root:hover {
  border: 1.4px solid var(--dark-text-green);
  margin: 0px;
}

.course-pill {
  background-color: var(--bg-light-purple);
  color: var(--dark-purple);
}

.course-root {
  border: 1.4px solid var(--dark-purple);
  margin: 1px;
  transition: all 0.3s linear;
}

.course-root:hover {
  border: 1.4px solid var(--dark-purple);
  margin: 0px;
}
