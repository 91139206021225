.root {
  position: fixed;
  z-index: 100;
  bottom: 40px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  margin: auto;
  background-color: white;
  animation: slideUp 0.5s forwards;
  padding-bottom: 20px;
}

.optionsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.header {
  border-bottom: 1px solid grey;
  padding: 20px 15px;
  text-align: center;
  position: relative;
}

.body {
  padding: 10px 20px;
}

.filterName {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.reset {
  font-weight: 600;
  font-size: 17px;
  position: absolute;
  top: 22px;
  right: 18px;
}

.ctaWrapper {
  text-align: center;
}

.applyFilterCTA {
  background-color: #3f57cb;
  color: white;
  border: 1px solid #d8d8d8;
  padding: 8px 0px;
  font-size: 16px;
  border-radius: 10px;
  width: 100%;
  margin-top: 12px;
}

@keyframes slideUp {
  from {
    bottom: -100px;
  }
  to {
    bottom: 40px;
  }
}
