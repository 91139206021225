.root {
  border: 1px solid #949292;
  padding: 6px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 550;
  color: #46464d;
}

.iconWrapper {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedRoot {
  background-color: #0b6d31;
  color: white;
  border: 1px solid #e9e2e2;
}

.hideIcon {
  display: none;
}
